import * as React from "react"
import Layout from "../components/layout"
import HomeSlider from "../components/home-slider"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const IndexPage = () => {
  return (
    <Layout>
    <main>
      <div className={'mx-auto max-w-screen-2xl w-full relative z-0'}>
        <HomeSlider />
      </div>
      <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-2 lg:gap-16'}>
          <div className={'lg:col-span-2'}>
            <h2 className={'text-3xl lg:text-4xl mb-4 uppercase'}>Jo Andrews Interiors - Interior Designer Belfast</h2>
            <p className={'text-xl lg:text-2xl italic font-light'}>Beautifully crafted interiors.</p>
          </div>
          <div>
            <div className={'lg:sticky lg:top-16 space-y-8'}>
                <div className={'prose-2xl'}>
                  <p className={'lead'}>Jo Andrews Interiors encompasses interior design, interior accessories and bespoke furniture, made in Belfast, Northern Ireland.</p>
                </div>
            </div>
          </div>
          <div className={'prose-lg'}>
            <p>Jo Andrews is quickly being recognised as the leading interior designer in Belfast.</p>
            <p>Our projects reflect our extensive experience in creating luxurious and liveable spaces for our clients in Northern Ireland and beyond.</p>
            <p>Our interior design library, in Belfast showcases a huge range of fabrics, wallpapers, bespoke furniture and accessories.</p>
            <p>Make an appointment for a free consultation or just call in any time to browse our range of furniture, accessories, wallpaper and fabrics.</p>
          </div>
        </div>
      </div>
      <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'lg:grid lg:grid-cols-2'}>
          <Link className={'block relative group bg-gray-100'} to="/services/interior-design">
            <div className={'aspect-[4/3] flex w-full'}>
              <StaticImage
                src="../images/interior-design.jpeg"
                alt="Interior design"
                layout="fullWidth"
                className={'w-full'}
              />
            </div>
            <div className={'absolute z-0 inset-0 pointer-events-none transition-colors bg-black/25 group-hover:bg-black/75'} />
            <div className={'p-6 absolute inset-0 flex flex-col items-center justify-center text-white text-center'}>
              <div className={'text-2xl lg:text-3xl font-bold font-sans uppercase'}>Interior Designer</div>
              <p className={'hidden lg:block text-lg opacity-0 group-hover:opacity-100 transition mt-2'}>Meticulous planning, insight and experience makes us your perfect interior design partner.</p>
              <div className={'hidden lg:block font-bold font-sans uppercase opacity-0 group-hover:opacity-100 transition mt-4'}>VIEW DETAIL</div>
            </div>
          </Link>
          <Link className={'block relative group bg-gray-100'} to="/services/sofa-chair/">
            <div className={'aspect-[4/3] flex w-full'}>
              <StaticImage
                src="../images/sofa-chair.jpeg"
                alt="Sofa & Chair Collection"
                layout="fullWidth"
                className={'w-full'}
              />
            </div>
            <div className={'p-6 absolute z-0 inset-0 pointer-events-none transition-colors bg-black/25 group-hover:bg-black/75'} />
            <div className={'absolute inset-0 flex flex-col items-center justify-center text-white text-center'}>
              <div className={'text-2xl lg:text-3xl font-bold font-sans uppercase'}>Sofa & Chair Collection</div>
              <p className={'hidden lg:block text-lg opacity-0 group-hover:opacity-100 transition mt-2'}>Bespoke furniture and reupholstery.</p>
              <div className={'hidden lg:block font-bold font-sans uppercase opacity-0 group-hover:opacity-100 transition mt-4'}>VIEW DETAIL</div>
            </div>
          </Link>
        </div>
      </div>
      <div className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
        <div className={'text-center'}>
          <div className={'text-3xl max-w-xl mx-auto mb-8 italic'}>
            Get in touch today to book your free design consultation.
          </div>

          <Link
            to="/contact-us/"
            className={'py-4 px-8 bg-gray-800 text-white hover:bg-white hover:text-gray-800 transition uppercase'}
          >Request a consultation</Link>

        </div>
      </div>
    </main>

    </Layout>
  )
}

export default IndexPage

export const Head = () => (
    <>
    <title>Interior Designer Belfast | Jo Andrews Interiors | Interiors & Furniture</title>
    <meta name="description" content="Jo Andrews Interior Designer Belfast. Belfast's leading Interior Design, Curtains, Upholstery &amp; Bespoke Furniture company." />
    </>
)
