import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import AliceCarousel from 'react-alice-carousel'
import { Link } from "gatsby"

const items = [
  <div className={'portrait:aspect-[3/4] landscape:aspect-[16/9] md:portrait:aspect-[16/9] md:aspect-[16/9] relative overflow-hidden bg-gray-100 flex select-none'}>
    <StaticImage
      src="../images/slider-1.jpg"
      alt=""
      loading="eager"
      className={'relative z-0 pointer-events-none w-full object-cover object-center'}
    />
    <div className={'absolute z-0 inset-0 pointer-events-none bg-black/50'} />
    <div className={'flex flex-col absolute inset-0 items-center justify-center text-white text-center p-6'}>
      <div className={'text-2xl lg:text-4xl'}>Design is in the detail</div>
      <div className={'text-3xl lg:text-5xl max-w-2xl uppercase font-bold font-sans'}>Creating beautiful, living spaces</div>
      <div className={'flex flex-wrap justify-center gap-4 lg:gap-8 mt-8'}>
        <Link to="/contact-us" className={'py-4 px-8 bg-gray-800 text-white hover:bg-white hover:text-gray-800 transition uppercase'}>Free Consultation</Link>
        <Link to="/services/interior-design" className={'py-4 px-8 text-gray-800 bg-white hover:text-white hover:bg-gray-800 transition uppercase'}>Our Design Services</Link>
      </div>
    </div>
  </div>,
  <div className={'portrait:aspect-[3/4] landscape:aspect-[16/9] md:portrait:aspect-[16/9] md:aspect-[16/9] relative overflow-hidden bg-gray-100 flex select-none'}>
    <StaticImage
      src="../images/slider-2.jpg"
      alt=""
      loading="eager"
      className={'pointer-events-none w-full object-cover object-center'}
    />
    <div className={'absolute z-0 inset-0 pointer-events-none bg-black/50'} />
    <div className={'flex flex-col absolute inset-0 items-center justify-center text-white text-center p-6'}>
      <div className={'text-2xl lg:text-4xl'}>Create something special</div>
      <div className={'text-3xl lg:text-5xl max-w-2xl uppercase font-bold font-sans'}>Beautifully Bespoke</div>
      <div className={'flex flex-wrap justify-center gap-4 lg:gap-8 mt-8'}>
        <Link to="/contact-us" className={'py-4 px-8 bg-gray-800 text-white hover:bg-white hover:text-gray-800 transition uppercase'}>Free Consultation</Link>
        <Link to="/services/sofa-chair" className={'py-4 px-8 text-gray-800 bg-white hover:text-white hover:bg-gray-800 transition uppercase'}>Our Services</Link>
      </div>
    </div>
  </div>,
  <div className={'portrait:aspect-[3/4] landscape:aspect-[16/9] md:portrait:aspect-[16/9] md:aspect-[16/9] relative overflow-hidden bg-gray-100 flex select-none'}>
    <StaticImage
      src="../images/slider-3.jpg"
      alt=""
      loading="eager"
      className={'pointer-events-none w-full object-cover object-center'}
    />
    <div className={'absolute z-0 inset-0 pointer-events-none bg-black/50'} />
    <div className={'flex flex-col absolute inset-0 items-center justify-center text-white text-center p-6'}>

      <div className={'text-3xl lg:text-5xl max-w-2xl uppercase font-bold font-sans'}>Collaboration</div>
      <div className={'text-2xl lg:text-4xl'}>Making your vision reality</div>
      <div className={'flex flex-wrap justify-center gap-4 lg:gap-8 mt-8'}>
        <Link to="/about-us" className={'py-4 px-8 text-gray-800 bg-white hover:text-white hover:bg-gray-800 transition uppercase'}>About Us</Link>
      </div>
    </div>
  </div>,
]

export default function HomeSlider() {
  return (
    <AliceCarousel
      autoPlay
      autoPlayStrategy="all"
      autoPlayInterval={5000}
      animationDuration={1000}
      infinite
      mouseTracking
      disableDotsControls
      disableButtonsControls
      items={items}
    />
  )
}
